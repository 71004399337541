<template>
  <div class="main-footer">
    <div class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <p>
            Biogen Australia Pty Ltd. ABN: 30 095 760 115. Macquarie Park, NSW 2113. Biogen NZ
            Biopharma Limited, Auckland, New Zealand. Biogen® and TYSABRI® are registered trademarks
            of Biogen MA Inc. ©2024.
          </p>
          <p>
            Biogen-226741.<br />
            BIOG1078/EMBC. Date of preparation: January 2024.
          </p>
          <p class="text-right">
            <img class="footer-logo" src="../assets/biogen_logo.png" alt="" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
