<template>
  <div id="app">
    <app-header @installApp="installApp" v-if="showInstallButton"></app-header>
    <app-content></app-content>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Content from '@/components/Content.vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import axios from './axios';

export default {
  name: 'App',
  components: {
    appContent: Content,
    appFooter: Footer,
    appHeader: Header,
  },
  data() {
    return {
      deferredPrompt: null,
      showInstallButton: false,
    };
  },
  methods: {
    record() {
      axios.get('/downloads/create/3.json');
    },
    async installApp() {
      this.deferredPrompt.prompt();
      const { outcome } = await this.deferredPrompt.userChoice;
      console.log(outcome);
      this.deferredPrompt = null;
      this.record();
    },
  },
  mounted() {
    axios.get('/downloads/create/2.json');
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showInstallButton = true;
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
      this.showInstallButton = false;
      console.log('PWA was installed');
    });
  },
};
</script>

<style lang="scss">
html, body, h2,h2,h3,h4,h5,h6,p,ul,li,ol,span,i {
  font-family: 'Libre Franklin', sans-serif;
  color: #5b5b5b;
}
body {
  width: 350px;
  margin: 0 auto;
}
p {
  font-size: 16px;
  margin-bottom: 20px;
}
.jost {
  font-family: 'Jost', sans-serif;
}
.grid-container {
  max-width: 900px;
  padding-left: 20px;
  padding-right: 20px;
}
.mb-0 {
  margin-bottom: 0;
}
.text-yellow {
  color: #ffb300;
}
.text-green {
  color: #72b764;
}
.text-blue {
  color: #6b8db7;
}
.text-bold {
  font-weight: 700;
}
.text-upper {
  text-transform: uppercase;
}
.text-small {
  font-size: 8px;
}
.bg-grey {
  background-color: #e6e6e7;
}
.bg-white {
  background-color: #FFF;
}
.main-footer {
  background-color: #FFF;
  padding-top: 80px;
  padding-bottom: 80px;
}
.main-footer p {
  font-size: 14px;
}
.footer-logo {
  margin-top: 40px;
  width: 400px;
}
.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #FFF;
  padding: 10px 0;
}
.install-link {
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: underline;
    .install-tip {
      opacity: 1;
    }
  }
}
.install-tip {
  display: inline-block;
  padding: 3px;
  text-transform: none;
}
.alert {
  padding-left: 60px;
  background-image: url('./assets/warning.png');
  background-position: left center;
  background-size: 30px auto;
  background-repeat: no-repeat
}
.app-title {
  color: #263692;
  font-size: 24px;
  font-weight: 900;
  margin-top: 40px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Jost', sans-serif;
}
.bullet {
  margin-bottom: 0;
  padding-left: 25px;
  background-image: url('./assets/chevron_bullet.png');
  background-size: 7px auto;
  background-repeat: no-repeat;
  background-position: left top 10px;
}
.tick {
  margin-bottom: 0;
  background-image: url('./assets/tick.png');
  background-size: 15px auto;
  background-repeat: no-repeat;
  background-position: left top 10px;
  padding-left: 25px;
}
.content-box-1 {
  position: relative;
  background-image: url('./assets/bg_1.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 920px;
  margin-bottom: 80px;
  &::after {
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    min-height: 310px;
    content: '';
    background-image: url('./assets/bg_2.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.content-box-2 {
  padding-top: 20px;
  background-image: url('./assets/bg_3.png');
  background-size: cover;
  background-position: center bottom;
  min-height: 1320px;
}
.content-box-3 {
  background-image: url('./assets/bg_4.png');
  background-size: cover;
  background-position: center top;
  min-height: 1000px;
}
.flyout {
  width: 70%;
  margin-top: 40px;
}
.headline {
  color: #009cde;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  font-family: 'Jost', sans-serif;
}
.headline-alt {
  color: #263692;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1;
  font-family: 'Jost', sans-serif;
}
.link {
  cursor: pointer;
}
.marg-top-sm {
  margin-top: 40px;
}
.marg-top-l {
  margin-top: 80px;
}
.marg-top-xl {
  margin-top: 160px;
}
.pad-top {
  padding-top: 20px;
}
.pad-top-l {
  padding-top: 40px;
}
.pad-top-xl {
  padding-top: 60px;
}
.pad-top-xxl {
  padding-top: 80px;
}
.pad-top-xxxl {
  padding-top: 100px;
}
.pad-top-xxxxl {
  padding-top: 220px;
}
.pad-bottom-l {
  padding-bottom: 40px;
}
.text-heavy {
  font-weight: bold;
}
.top-tysabri-logo {
  margin-top: 160px;
}
.left,
.right {
  color: #ff671f;
  font-family: 'Jost', sans-serif;
  font-weight: 900;
  font-size: 18px;
  cursor: pointer;
}
.left {
  margin-right: 30px;
}
.leave-arrow {
  width: 15px;
}
.modal {
  position: fixed;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  overflow: scroll;
  background-color: #FFF;
  z-index: 199;
  box-shadow: 3px 3px 2px 0px #F9AE08;
  padding: 40px;

  p {
    font-size: 16px;
  }
}
@media all and (min-width: 600px) {
  body {
    width: 600px;
  }
  p {
    font-size: 20px;
  }
  .app-title {
    margin-top: 80px;
    font-size: 44px;
  }
  .headline,
  .headline-alt {
    font-size: 42px;
  }
  .marg-top-l {
    margin-top: 120px;
  }
  .top-tysabri-logo {
    margin-top: 200px;
  }
  .content-box-1 {
    min-height: 1300px;
    background-size: cover;
    &::after {
      bottom: -300px;
      min-height: 380px;
    }
  }
  .content-box-2 {
    padding-top: 280px;
  }
}
@media all and (min-width: 1000px) {
  body {
    width: 1000px;
  }
  .content-box-1::after {
    background-size: cover;
  }
  .content-box-2 {
    min-height: 1600px;
    padding-top: 340px;
  }
  .pad-top-l {
    padding-top: 60px;
  }
}
</style>
