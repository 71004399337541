<template>
  <div>
    <div class="content-box-1">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <img class="top-tysabri-logo" src="../assets/tysabri_logo_white.png" alt="" />
            <h1 class="app-title">Patient alert card information</h1>
            <p class="text-center pad-top">
              <img
                @click="scrollDown"
                class="link"
                width="100"
                src="../assets/down_button.png"
                alt="" />
            </p>
            <img class="marg-top-l link" @click="goToCmi" src="../assets/cmi_button.png" />
            <img class="marg-top-sm link" @click="goToPaq" src="../assets/question_button.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="content-box-2">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <p>
              This digital alert card contains important safety information that you need to be
              aware of before you are given TYSABRI and during treatment with TYSABRI.
            </p>
            <p class="headline pad-top-l pad-bottom-l">
              DO NOT HAVE YOUR TYSABRI TREATMENT IF:
            </p>
            <p class="alert">
              You have ever had a rare brain infection called progressive multifocal
              leukoencephalopathy (PML)
            </p>
            <p class="alert">
              You have a serious infection or have problems with your immune system
            </p>
            <p class="alert">
              You are currently taking other medications such as interferon or glatiramer acetate
              for treatment of multiple sclerosis (MS)
            </p>
            <p class="headline pad-top-l pad-bottom-l">
              DURING TREATMENT WITH TYSABRI
            </p>
            <p>
              There have been reports of a rare brain infection called PML (progressive multifocal
              leukoencephalopathy) that have occurred in patients who have been given TYSABRI. PML
              is a serious condition and can cause severe disability or even death.
            </p>
            <p>
              The risk of PML increases with treatment duration, especially beyond 2 years. The risk
              of PML also increases if you have previously taken an immunosuppressant medicine or
              if you have been exposed to John Cunningham Virus (JCV). Your doctor will order blood
              tests to check for JCV.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <p class="headline pad-top-l">
            SIGNS AND SYMPTOMS OF PML:
          </p>
          <p>
            The symptoms of PML may be similar to an MS relapse. Therefore, if you believe your MS
            is getting worse or if you notice any new symptoms that last or worsen over several
            days, it is important that you speak to your doctor as soon as possible.
          </p>
          <p class="headline-alt">
            Signs
          </p>
          <p class="bullet">
            Changes in mood, mental ability and concentration
          </p>
          <p class="bullet">
            Behavioural changes
          </p>
          <p class="bullet">
            Weakness on one side of the body
          </p>
          <p class="bullet">
            Vision problems
          </p>
          <p class="headline-alt pad-top-l">
            Symptoms
          </p>
          <p class="bullet">
            Similar to symptoms of an MS relapse. If you believe your MS is getting worse or if you
            notice any new symptoms that last longer or worsen over several days, contact your
            neurologist immediately.
          </p>
        </div>
      </div>
    </div>
    <div class="flyout">
      <img src="../assets/discuss_box.png" alt="" />
    </div>
    <div class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <p class="headline pad-top-l">
            SERIOUS INFECTIONS
          </p>
          <p>
            Other serious infections may occur with TYSABRI. Speak to your doctor as soon as
            possible if you think you have developed an infection, for example, a persistent
            fever.
          </p>
          <p class="text-heavy">
            The symptoms of infections include:
          </p>
          <p class="bullet">
            an unexplained fever
          </p>
          <p class="bullet">
            severe diarrhoea
          </p>
          <p class="bullet">
            shortness of breath
          </p>
          <p class="bullet">
            prolonged dizziness
          </p>
          <p class="bullet">
            stiff neck
          </p>
          <p class="bullet">
            weight loss
          </p>
          <p class="bullet">
            headache
          </p>
          <p class="bullet pad-bottom-l">
            listlessness
          </p>
        </div>
      </div>
    </div>
    <div class="content-box-3">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12">
            <p class="marg-top-xl tick">
              Keep this with you at all times, even 6 months after the last dose of TYSABRI, since
              side effects may occur even after you have stopped treatment with TYSABRI
            </p>
            <p class="tick">
              Show this to any doctor or nurse involved with your treatment, not only your
              neurologist
            </p>
            <p class="tick">
              Read the TYSABRI Consumer Medicine Information to ensure that you have the most up to
              date information
            </p>
            <p class="text-center pad-top-xxl">
              <img width="400px" src="../assets/tysabri_logo.png" alt="" />
            </p>
          </div>
        </div>
      </div>
    </div>
    <LeaveSite @close="leaveSite = false" v-if="leaveSite" />
  </div>
</template>

<script>
import LeaveSite from './LeaveSite.vue';

export default {
  name: 'Content',
  components: {
    LeaveSite,
  },
  data() {
    return {
      leaveSite: false,
    };
  },
  methods: {
    goToCmi() {
      this.leaveSite = true;
    },
    goToPaq() {
      window.open('https://biogenlinc-assets-bucket.s3.eu-central-1.amazonaws.com/TYS_Pre-administration_Questionnaire_AU_FINAL.pdf', '_blank').focus();
    },
    async handleHardReload(url) {
      await fetch(url, {
        headers: {
          Pragma: 'no-cache',
          Expires: '-1',
          'Cache-Control': 'no-cache',
        },
      });
      window.location.href = url;
      window.location.reload();
    },
    reloadSite() {
      this.handleHardReload(window.location.href);
    },
    scrollDown() {
      const width = document.body.clientWidth;
      if (width === 350) {
        window.scrollTo(0, 980);
      }
      if (width === 600) {
        window.scrollTo(0, 1550);
      }
      if (width === 1000) {
        window.scrollTo(0, 1650);
      }
    },
  },
};
</script>
