<template>
  <div class="modal">
    <p class="text-center">
      You are now leaving<br />Biogen's Digital Patient Alert Card
    </p>
    <p class="text-center">
      The information you are about to be referred to may not comply with the Australian
      regulatory requirements. Further information relevant to the Australian environment is
      available from the Company or via the Product Information or Data Sheet.
    </p>
    <p class="text-center">
      <span @click="goBack" class="left">
        <img class="leave-arrow" src="../assets/back_button.png" alt="" />
        &nbsp;
        Go back
      </span>
      <span @click="goToPaq" class="right">
        OK
        &nbsp;
        <img class="leave-arrow" src="../assets/go_button.png" alt="" />
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LeaveSite',
  methods: {
    goBack() {
      this.$emit('close');
    },
    goToPaq() {
      window.open('https://rss.medsinfo.com.au/bd/rss.cfm?product=bdctysab', '_blank').focus();
    },
  },
};
</script>
