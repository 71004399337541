<template>
  <div
    class="grid-x grid-padding-x main-header">
    <div class="cell small-12">
      <span @click="install" class="text-blue text-upper install-link">
        Install
        <span class="install-tip">
          You can install this website to your device so it is available offline in case you need
          it when you have no internet connection.
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    install() {
      this.$emit('installApp');
    },
  },
};
</script>
